'use client';

import Link from 'next/link';
import React, { ReactElement } from 'react';
import moment from 'moment';
import styles from './styles';
import { cx } from 'styled-system/css';
import { invalidateHomePath } from '@/app/actions';
import { useMenuContext } from '@/hooks';
type Props = {
  children: ReactElement;
  user: any;
};
const ControlCenter = ({
  children,
  user
}: Props): ReactElement => {
  const {
    collapsed: menuIsCollapsed,
    open: menuIsOpen,
    scrolledBottom,
    setIsCollapsed: setMenuIsCollapsed,
    setIsOpen: setMenuIsOpen
  } = useMenuContext();
  const onHomeClick = () => {
    try {
      invalidateHomePath();
      setMenuIsOpen(false);
    } catch (error) {
      console.log(error);
    }
  };
  return <div className={styles.main} data-menu-collapsed={user && !menuIsOpen && menuIsCollapsed} data-menu-open={menuIsOpen} data-sentry-component="ControlCenter" data-sentry-source-file="ControlCenter.tsx">
      <div className={cx('wrapper', styles.wrapper)} data-scrolled-bottom={scrolledBottom} id='control-center'>
        <div className={cx('content', styles.content)}>
          <Link className={styles.linkHeader} href='/' onClick={onHomeClick} data-sentry-element="Link" data-sentry-source-file="ControlCenter.tsx">
            <h3 className={styles.header}>
              <i className={cx('icon', styles.icon, 'fa-home-alt')} />
              <span className='header'>
                Home
              </span>
            </h3>
          </Link>
          {children}
        </div>
        <div className={styles.footerWrapper}>
          <footer className={cx('footer', styles.footer)}>
            <h5 className={styles.footerCopyright}>&copy; 2014 - {moment().format('YYYY')} <strong>SLAENT, LLC.</strong> All Rights Reserved.</h5>
            <div className={styles.footerLinks}>
              <Link className={styles.footerLink} href='/' data-sentry-element="Link" data-sentry-source-file="ControlCenter.tsx">Terms of Service</Link>
              <Link className={styles.footerLink} href='/' data-sentry-element="Link" data-sentry-source-file="ControlCenter.tsx">Privacy Policy</Link>
              <Link className={styles.footerLink} href='/' data-sentry-element="Link" data-sentry-source-file="ControlCenter.tsx">User Rules</Link>
              <Link className={styles.footerLink} href='/' data-sentry-element="Link" data-sentry-source-file="ControlCenter.tsx"><strong>Contact Us</strong></Link>
            </div>
          </footer>
          {user ? <button className={cx('collapse-button', styles.collapse)} onClick={() => setMenuIsCollapsed(!menuIsCollapsed)}>
                  <i className={cx(styles.icon, styles.collapseIcon, menuIsCollapsed ? 'fa-arrow-right' : 'fa-arrow-left')} />
                </button> : null}
        </div>
      </div>
    </div>;
};
export default ControlCenter;